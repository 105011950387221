import * as React from "react"
import Layout from "../components/Layout"

function Shop() {
  if (typeof document !== `undefined`) {
    let spreadshopConfig = document.createElement("script");
    spreadshopConfig.innerHTML = `
      var spread_shop_config = {
        shopName: 'bootstrappingtools',
        locale: 'us_US',
        prefix: 'https://bootstrappingtools.myspreadshop.com',
        baseId: 'myShop'
      }
    `
    document.body.appendChild(spreadshopConfig)
  
    let spreadshopScript = document.createElement("script");
    spreadshopScript.type = "text/javascript";
    spreadshopScript.src = "https://bootstrappingtools.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js";
    document.body.appendChild(spreadshopScript)
  }


  return (
    <Layout>
      <div className="container has-text-centered ma-5 pa-5">
        <div id="myShop">
          <i id="spinner" className="fa-solid fa-spinner fa-pulse fa-2xl" aria-hidden="true"/>
          <p>Loading The Shop...</p>
        </div>
      </div>
    </Layout>
  );
}

export default Shop;